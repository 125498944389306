body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background-color: #FFFEF9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* start of desktop styles */

html {
  height: 100%;
}

.App {
  text-align: center;
}

.App-header {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB6UAAAABCAYAAACWnXuhAAABeElEQVRYhc1YSRLDMAgT+f+b7R4y6bgpXsCCRJdusViMMK6UUisIoJBofARiH0UFIMpqQXEyirKsuJjGYO6FytVLzQ6nEzSuqr7dRoEQ2U5M/TMGEKZdClkFRHB2qbbw2gL0FSM77lUta31gl9MCdtwAwDlFbpxk6ZhcNDwckU+Av/dX3UXUFNDLw6XV3ndjLS/1upecRTTuycJIn4G4+oiuvxbROfra2ay/ZTux9Ddb0n5Isjn6xd8/RpQRZ5YFkTqwzBgXMnTJRvd+AqTo8lEo8xH/tpGDw/g8eza8w+oPG2KKr9f/2rvSOlMK5HrJE2hKbJJjJ8NGq4EIvUVrbOqyXR7r3Jtw809iks5/mr89A/idq+beRObD1gvXEFV7y64aYorIrWc+7OLAd3iM2KuI+Ff2v8qZp5XeF1FPtlNb29C7fitENppexwITrrFnsojp4xEwr4jWfr1cu84wOZX9eZV/CjQd78w+/3z/mkydzxvzj2p3gA/0j3P87dIc6QAAAABJRU5ErkJggg==);
  background-position: center;
  height: 3vh;
}

.App-main article {
  display: -webkit-flex;
  display: flex;
}

.App-main-logo {
  margin: 50px 0 0 0;
  display: -webkit-flex;
  display: flex;
}

.App-main-phone {
  -webkit-flex-basis: 300px;
          flex-basis: 300px;
  display: -webkit-flex;
  display: flex;
}

.App-phone {
  height: 600px;
}

.App-main-text {
  margin: 60px 0;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.App-main-text span {
  font-size: 20px;
  font-weight: 300;
}

.App-main-text h2 {
  margin: 50px 0 0 0;
  font-size: 28px;
  font-weight: 300;
}

.App-main-form {
  margin: 0 0 0 50px;
  -webkit-flex-direction: column;
          flex-direction: column;
  display: -webkit-flex;
  display: flex;
}

.App-main-text .emailForm {
  display: block;
}

.App-main-text .emailFormMessage {
  display: block;
  font-weight: 300;
  font-size: 20px;
  margin: 30px 0 0 0;
}

.App-main-text .email {
  margin: 20px 0;
  padding: 0;
  height: 40px;
  width:80%;
  text-align: center;
  font-size: 20px;
  background: none;
  color: gray;
  border-radius: 2px;
  border: 1px solid lightgray;
  -webkit-appearance: none;
  outline: none;
}

.App-main-text .email::-webkit-input-placeholder {
  color: lightgray;
  opacity: 1;
  transition: opacity .5s;
}

.App-main-text .email::-ms-input-placeholder {
  color: lightgray;
  opacity: 1;
  transition: opacity .5s;
}

.App-main-text .email::placeholder {
  color: lightgray;
  opacity: 1;
  transition: opacity .5s;
}

.App-main-text .email:focus::-webkit-input-placeholder { 
  opacity: 0; 
}

.App-main-text .email:focus::-ms-input-placeholder { 
  opacity: 0; 
}

.App-main-text .email:focus::placeholder { 
  opacity: 0; 
}

.App-main-text .reserve {
  margin: 0;
  padding: 0;
  width: 50%;
  height: 50px;
  font-size: 24px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 1px 1px 1px lightgray;
  border: 0;
  background: linear-gradient(#FE9800 , #F5CC87);
  font-weight: 500;
  cursor:pointer;
  outline: none;
}

.App-main-text .reserve:active {
  -webkit-filter: saturate(125%);
          filter: saturate(125%);
}

.App-footer {
  height: 7vh;
  display: -webkit-flex;
  display: flex;
}

.App-footer-nav {
  width: 100%;
  padding: 2.5vh 0;
}

.App-footer-nav a {
  text-decoration: none;
  padding: 0 20px 0 20px;
  color: darkgray;
}

.App-footer-nav a:visited {
  color: darkgray;
}

.App-footer-nav span {
  padding: 0 20px 0 20px;
  color: darkgray;
}

.App-logo {
  /*animation: App-logo-spin infinite 20s linear;*/
  pointer-events: none;
  height: 90px;
  width: 90px;
  object-fit: cover;
  object-position: -265px 0px;
  margin: 4px 2px;
}

.App-main {
  min-height: 90vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 10px;
  color: black;
  max-width: 800px;
  margin: auto;
}

.App-main .hero {
  margin: 20px auto 0 auto;
  overflow: hidden;
  text-indent: 110%;
  white-space: nowrap;
  width: 280px;
  height: 80px;
  background-image: url(/static/media/sprite.a3254234.png);
  background-repeat: no-repeat;
  background-position: -4px -18px;
  background-size: 400px;
}

.App-main p {
  font-size: 16px;
}

.App-main li {
  font-size: 16px;
}

.App-main li a {
  text-decoration: none;
  color: darkgray;
}

.App-main li a:visited {
  color: darkgray;
}

.App-link {
  color: #61dafb;
}

.privacy {
  text-align: justify;
  width:80%;
}


@media screen and (max-width: 991px) {
/* start of large tablet styles */

  .App-main-form {
    margin: 0 0 0 20px;
  }

}

@media screen and (max-width: 767px) {
/* start of medium tablet styles */

  .App-main-phone {
    display: none;
  }

  .App-main-form {
    margin: 0;
  }

  .App-main {
    min-height: 75vh;
  }

}

@media screen and (max-width: 479px) {
/* start of phone styles */

  .App-main-logo {
    margin: 0;
    display: block;
  }

  .App-main-logo h1 {
    margin: 0;
  }

  .App-logo {
    margin: 14px;
  }

  .App-main-text {
    margin: 0;
  }

  .App-main-text span {
    font-size: 16px;
  }

  .App-main-text h2 {
    margin: 10px 0 0 0;
    font-size: 24px;
  }

  .App-main-text .email {
    width: 80%;
  }

  .App-main-text .reserve {
    width: 80%;
  }

}

